import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='sm:px-8 mt-16 sm:mt-32'>
        <section className='relative px-4 sm:px-8 lg:px-12'>
          <div className='mx-auto max-w-2xl lg:max-w-5xl'>
            <div className='grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12'>
              <div className='lg:pl-20 group'>
                <div className='max-w-xs px-2.5 lg:max-w-none'>
                  <img
                    src='/images/developer2.jpg'
                    alt='Us'
                    loading='lazy'
                    width='800'
                    height='800'
                    className='aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover group-hover:rotate-0 group-hover:scale-[1.04]'
                  />
                </div>
                <div className='absolute rotate-3 p-1 bg-slate-200/80 text-slate-400 group-hover:rotate-0 group-hover:ml-5 group-hover:mt-2 dark:bg-black'>
                  <p className='text-xs'>
                    {t("intro.photo")}{" "}
                    <a
                      className='hover:underline'
                      href='https://unsplash.com/@domenicoloia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
                    >
                      Domenico Loia
                    </a>{" "}
                    {t("intro.photo2")}{" "}
                    <a
                      className='hover:underline'
                      href='https://unsplash.com/es/fotos/hGV2TfOh0ns?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
                    >
                      Unsplash
                    </a>
                  </p>
                </div>
              </div>
              <div className='lg:order-first lg:row-span-2'>
                <h1>{t("about.title")}</h1>
                <div className='mt-6 space-y-7 text-base'>
                  <p>{t("about.content")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;

import { FaUserAstronaut } from "react-icons/fa";
import { GiGreatPyramid, GiSpaceShuttle } from "react-icons/gi";
import { useTranslation } from "react-i18next";
const Features = () => {
  const { t } = useTranslation();
  return (
    <section
      className='pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32 dark:bg-black'
      aria-label='Razones para contratarnos'
    >
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div id='features' className='mx-auto max-w-2xl md:text-center'>
          <h2>{t("features.title")}</h2>
          <p className='mt-4'>{t("features.content")}</p>
        </div>
        <div className='mt-10 md:mt-20 lg:block'>
          <div
            className='grid md:grid-cols-3 gap-x-8 gap-y-5'
            role='tablist'
            aria-orientation='horizontal'
          >
            <div className='relative'>
              <div className='w-9'>
                <GiSpaceShuttle size={50} />
              </div>
              <h3>{t("features.internet")}</h3>
              <p className='mt-4'>{t("features.internetContent")}</p>
            </div>
            <div className='relative'>
              <div className='w-9'>
                <FaUserAstronaut size={50} />
              </div>
              <h3>{t("features.partners")}</h3>
              <p className='mt-4'>{t("features.partnersContent")}</p>
            </div>
            <div className='relative'>
              <div className='w-9'>
                <GiGreatPyramid size={50} />
              </div>
              <h3>{t("features.success")}</h3>
              <ol className='list-decimal mt-4 ml-5 text-md text-slate-600 dark:text-slate-400'>
                <li>{t("features.successContent")}</li>
                <li>{t("features.successContent2")}</li>
                <li>{t("features.successContent3")}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;

import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { HiXMark, HiBars3 } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  let { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <Disclosure as='nav' className='bg-white shadow dark:bg-black'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-7xl px-4 sm:p-4 lg:px-8'>
            <div className='flex h-16 justify-between'>
              <nav
                id='main-menu'
                className='flex flex-col items-center justify-center mx-auto space-y-4'
              >
                <div className='flex flex-shrink-0 items-center'>
                  <img
                    className='block h-8 w-auto lg:hidden dark:filter dark:invert'
                    src='/images/logo-soluciones-io.png'
                    alt='Soluciones iO'
                  />
                  <img
                    className='hidden h-8 w-auto lg:block dark:filter dark:invert'
                    src='/images/logo-soluciones-io.png'
                    alt='Soluciones iO'
                  />
                </div>
                <div className='hidden sm:ml-6 sm:flex sm:space-x-8'>
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <NavLink to='/' className='main-menu-item'>
                    {t("nav.home")}
                  </NavLink>
                  <NavLink to='/sobre-nosotros' className='main-menu-item'>
                    {t("nav.about")}
                  </NavLink>
                  <NavLink to='/portafolio' className='main-menu-item'>
                    {t("nav.portfolio")}
                  </NavLink>
                  <NavLink to='/contacto' className='main-menu-item'>
                    {t("nav.contact")}
                  </NavLink>
                  <LanguageSwitcher />
                </div>
              </nav>
              <div className='-mr-2 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:text-gray-400 dark:hover:bg-gray-900'>
                  <span className='sr-only'>Abrir Menú</span>
                  {open ? (
                    <HiXMark className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <HiBars3 className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 pb-3 pt-2'>
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Disclosure.Button
                as='a'
                href='/'
                className={clsx(
                  "mobile-main-menu-item",
                  pathname === "/" && "mobile-main-menu-item-active"
                )}
              >
                {t("nav.home")}
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='/sobre-nosotros'
                className={clsx(
                  "mobile-main-menu-item",
                  pathname === "/sobre-nosotros" &&
                    "mobile-main-menu-item-active"
                )}
              >
                {t("nav.about")}
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='/portafolio'
                className={clsx(
                  "mobile-main-menu-item",
                  pathname === "/portafolio" && "mobile-main-menu-item-active"
                )}
              >
                {t("nav.portfolio")}
              </Disclosure.Button>
              <Disclosure.Button
                as='a'
                href='/contacto'
                className={clsx(
                  "mobile-main-menu-item",
                  pathname === "/contacto" && "mobile-main-menu-item-active"
                )}
              >
                {t("nav.contact")}
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

import ContactForm from "../components/ContactForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
const ContactUs = () => {
  const { t } = useTranslation();
  const rKey = process.env.RECAPTCHA_SITE_KEY;
  return (
    <div className='sm:px-8 mt-16 sm:mt-32'>
      <section className='relative px-4 sm:px-8 lg:px-12'>
        <div className='mx-auto max-w-2xl lg:max-w-5xl'>
          <div className='grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12'>
            <div className='lg:pr-20 group'>
              <div className='max-w-xs px-2.5 lg:max-w-none'>
                <img
                  src='/images/contact-us.webp'
                  alt='Radio telescope in the Atacama Desert, Chile'
                  loading='lazy'
                  width='800'
                  height='800'
                  className='aspect-square -rotate-3 rounded-2xl bg-zinc-100 object-cover group-hover:rotate-0 group-hover:scale-[1.04]'
                />
              </div>
              <div className='absolute -rotate-3 mt-1 ml-10 p-1 bg-slate-200/80 text-slate-400 group-hover:rotate-0 group-hover:ml-5 group-hover:mt-2 dark:bg-black'>
                <p className='text-xs'>
                  {t("intro.photo")}{" "}
                  <a
                    className='hover:underline'
                    href='https://unsplash.com/es/@wizwow?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
                  >
                    Donald Giannatti
                  </a>{" "}
                  {t("intro.photo2")}{" "}
                  <a
                    className='hover:underline'
                    href='https://unsplash.com/es/fotos/4qk3nQI3WHY?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
                  >
                    Unsplash
                  </a>
                </p>
              </div>
            </div>
            <div className='lg:row-span-2'>
              <h1 className='text-2xl font-bold mb-4'>{t("contact.title")}</h1>
              <h2 className='text-lg font-semibold mb-4'>
                {t("contact.subtitle")}
              </h2>
              <p className='mb-10'>{t("contact.content")}</p>
              <GoogleReCaptchaProvider
                reCaptchaKey='6LcnUXImAAAAALwuaeEKSOB0g3lZ2_b6mijOpRMc'
                language='es'
              >
                {rKey}
                <ContactForm />
              </GoogleReCaptchaProvider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;

import React from "react";
import Project from "../components/Project";
import { useTranslation } from "react-i18next";

const Portfolio = () => {
  const { t } = useTranslation();
  const projects = t("projects.items", { returnObjects: true });
  return (
    <>
      <section className='flex flex-col items-center p-4 sm:p-8 lg:p-12'>
        <div>
          {projects.map((project, index) => (
            <React.Fragment key={index}>
              <Project {...project} />
            </React.Fragment>
          ))}
        </div>
      </section>
    </>
  );
};
export default Portfolio;

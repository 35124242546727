export const en = {
  nav: {
    home: "Home",
    about: "About Us",
    portfolio: "Portfolio",
    contact: "Contact",
  },
  intro: {
    title: "Modern web page and application",
    accent: "development",
    content:
      "We create beautifully designed websites. Easy to Maintain. Return on your investment.",
    photo: "Photo by",
    photo2: "on",
  },
  features: {
    title: "Practical and Effective Development",
    content:
      "We power your online presence with cutting-edge web design and effective digital strategies. Let your business shine on the web with our team of experts in marketing and web development! Contact us today for a personalized analysis.",
    internet: "Internet, the final frontier",
    internetContent:
      "We transform your digital presence into real and measurable results. It's not just about having a website, but about building a tool that works for you and generates revenue.",
    partners: "We work with you, not just for you",
    partnersContent:
      "We're not just a service provider, we're your strategic partner. We accompany you in every step of the process, from planning to execution, making sure that your web project or digital strategy has a real impact on your business.",
    success: "The 3 pillars of digital success:",
    successContent:
      "Attract the right customers → We implement strategies to generate quality traffic that actually converts into business opportunities.",
    successContent2:
      "Convert more visitors into customers → We use marketing techniques to convert visitors into customers.",
    successContent3:
      "Foster and grow your community → You don't just get customers, you build lasting relationships that drive your business forward.",
  },
  about: {
    title:
      "We are Soluciones iO, from the Costa del Sol, Spain, improving the world, one web app at a time.",
    content:
      "We started working on the web development of our own companies, and the companies of some friends and family. Along the way we learned and developed little by little our brand. The success achieved by them through Our work and the accumulated experience pushed us to iO Solutions, to be able to offer our knowledge to other people.",
  },
  services: {
    title: "Our Services",
    subtitle: "We are prepared to offer you high-quality services.",
    webdev: {
      title: "Web Apps Development",
      description:
        "Organization and integration of web resources and apps for your organization's presence on the Internet.",
      cta: "More Information",
    },
    seo: {
      title: "Search Engine Optimization (Google)",
      description:
        "We optimize your website, or create content to help you rank high in search engines.",
      cta: "More Information",
    },
    wordpress: {
      title: "WordPress Maintenance",
      description:
        "Maintenance service plans to ensure your website stays updated, secure, and fast.",
      cta: "More Information",
    },
  },
  contact: {
    title: "Contact",
    subtitle: "Let's talk",
    content:
      "We help all types of businesses to have the best possible presence on the Internet. Drop us a message:",
    name: "Name",
    email: "Email",
    message: "Message",
    messageRequired: "A message is required.",
    send: "Send",
    success: "Message sent successfully",
    error: "There was an error with your request",
    nameRequired: "A name is required.",
    emailRequired: "An email is required.",
  },
  pageNotFound: {
    title: "🤨 This page does not exist 🤔",
    subtitle: "Try going back to the home page 🏠",
    backToHome: "Back to home",
  },
  projects: {
    items: [
      {
        title: "La Furia Travel Club",
        description:
          "Web application that sends the best flight prices from the user's chosen departure airport. Built with NextJS (React) and the backend scrapes Google Flights to find the best deals.",
        tags: ["React", "NextJS", "Tailwind CSS", "shadcn"],
        imageUrl: "projects/lafuriatravel-club.webp",
        link: "https://lafuriatravel.club",
      },
      {
        title: "Portafolio Ricardo Rodriguez",
        description:
          "Web developer portfolio made in React with effects using Framer Motion. Translation and dark theme.",
        tags: ["React", "Framer Motion", "Tailwind CSS"],
        imageUrl: "projects/portfolio-ricardo.webp",
        link: "https://ricardo-rodriguez-portafolio.vercel.app/",
      },
      {
        title: "Ruby on Rails Billing App",
        description:
          "Web application for invoicing for freelancers or small businesses. Made in Ruby on Rails with the frontend improved with Stimulus and AlpineJS. Emits invoices in PDF to print or send by email.",
        tags: ["Ruby on Rails", "Stimulus", "AlpineJS", "Tailwind CSS"],
        imageUrl: "projects/invoicing-rails.webp",
        link: "https://fact.solucionesio.es",
      },
      {
        title: "Los Flamingos, Marbella",
        description:
          "The objective of this portal was to present the best offers of the urbanization Los Flamingos, and use SEO methods and Google Ads to make a much more specific marketing, especially for British clients.",
        tags: ["WordPress", "Genesis", "Resales"],
        imageUrl: "projects/losflamingos.jpg",
      },
      {
        title: "MLeon Pro",
        description:
          "Blog for a cryptocurrency investment consulting company, including a section to inform about upcoming courses.",
        tags: ["WordPress", "Genesis"],
        imageUrl: "projects/mleon-pro.jpg",
      },
      {
        title: "Long Term Rentals Marbella",
        description:
          "Portal for long-term rentals for the real estate company One Marbella in the Costa del Sol, Spain. The objective of this portal was to present the best offers in long-term rentals and use SEO methods and Google Ads to make a much more specific marketing, especially for British clients.",
        tags: ["WordPress", "Genesis", "Resales"],
        imageUrl: "projects/longtermrentals.jpg",
      },
      {
        title: "Jacksonville Dutch Croquettes",
        description:
          "A company that distributes Dutch croquettes made by Voorn Croquettes. Original design using the Genesis Framework in WordPress. Some user improvements in the volume price selection with javascript.",
        tags: ["WordPress", "WooCommerce", "Genesis", "Stripe", "MailChimp"],
        imageUrl: "projects/jax-croquettes.jpg",
      },
      {
        title: "YupiUSA",
        description:
          "This is the distributor of the equipment of Suministros Yupi in the state of Florida, USA.",
        tags: ["WordPress", "WooCommerce", "MailChimp", "JavaScript"],
        imageUrl: "projects/yupiusa.jpg",
      },
      {
        title: "Suministros Yupi, C.A.",
        description:
          "Leading company in the sale and distribution of cleaning equipment and materials in Venezuela. The WooCommerce extension had to be adjusted and molded to work in catalog mode so that potential clients can request a quote for the equipment in a list.",
        tags: ["WordPress", "WooCommerce", "MailChimp", "JavaScript"],
        imageUrl: "projects/suministrosyupi.jpg",
      },
      {
        title: "Re-diseño MarbellaBanus",
        description:
          "The client requested a refresh of their website and incorporate a Blog. Same features as the original, but including better texts and starting a Blog to attract more traffic.",
        tags: ["WordPress", "WP Casa", "MailChimp", "Google Ads"],
        imageUrl: "projects/redesign-marbellabanus.jpg",
      },
      {
        title: "Stars Skincare",
        description:
          "Spa of care and beauty esthetic in Boca Ratón, Florida, USA. Built with WordPress, WooCommerce, and Stripe to process payments. A MailChimp list was created, and Google Ads for marketing. Additionally, we were consultants for the configuration and launch of Google Mail and Docs.",
        tags: ["WordPress", "WooCommerce", "Stripe", "MailChimp", "Google Ads"],
        imageUrl: "projects/starsskincare-com.jpg",
      },
      {
        title: "Voorn Croquettes",
        description:
          "A company that produces and sells Dutch croquettes in the USA. Page built with WordPress, WooCommerce, and Stripe as a payment gateway. Uses a custom theme designed according to the client's requirements. A MailChimp list was also created for post-sale marketing. Finally, we were consultants to design the workflow for processing orders, as the croquettes had to be sent frozen.",
        tags: ["WordPress", "WooCommerce", "Stripe", "MailChimp"],
        imageUrl: "projects/voorncroquettes-com.jpg",
      },
      {
        title: "IQ Board Venezuela",
        description:
          "Web presentation of a single page for the distributor of interactive screens of the IQBoard brand for Venezuela.",
        tags: ["HTML5", "CSS3", "JavaScript"],
        imageUrl: "projects/focus-tech-com-ve.jpg",
      },
      {
        title: "Focus Tech, C.A.",
        description:
          "Catalog for a technology integrator company for presentations. Made with WordPress and a custom template. We sought simplicity and usability.",
        tags: ["WordPress", "CSS3"],
        imageUrl: "projects/focus-tech-com-ve.jpg",
      },
      {
        title: "Agencia Inmobiliaria Marbella Banus",
        description:
          "One of our first projects. Built with HTML, jQuery, and Bootstrap. The backend used a PHP script to receive property listings in XML format from the Resales API, which is a database provider for real estate companies in the Costa del Sol region, south of Spain. Additionally, a script was programmed to synchronize an old database with some listings from the Costa del Sol.",
        tags: ["HTML5", "CSS3", "jQuery", "PHP", "Bootstrap"],
        imageUrl: "projects/marbellabanus-com.jpg",
      }, // ... other projects
    ],
  },
  // Add more translation keys as needed
};
